import React from "react"

const Tiles = () => {
  return (
    <div className="uk-section uk-animation-slide-right-medium">
      <div className="uk-container uk-container-large">
        <div
          className="uk-child-width-1-2@s uk-grid-collapse uk-text-center uk-box-shadow-large"
          uk-grid="true"
          uk-scrollspy="target: h5; cls:uk-animation-fade; delay: 500"
        >
          <div>
            <div className="uk-tile uk-tile-default tileDarkText uk-height-1-1">
              <h5 className="uk-h5">Collaboration</h5>
            </div>
          </div>
          <div>
            <div className="uk-tile uk-tile-muted tileDarkText uk-height-1-1">
              <h5 className="uk-h5">Ethics</h5>
            </div>
          </div>
          <div>
            <div
              className="uk-tile tileLightText uk-height-1-1"
              style={{ background: "#617F7F" }}
            >
              <h5 className="uk-h5">Transparency</h5>
            </div>
          </div>
          <div>
            <div
              className="uk-tile tileLightText uk-height-1-1"
              style={{ background: "#000000" }}
            >
              <h5 className="uk-h5">Trust</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tiles
