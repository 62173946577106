import React, { useState } from "react"
import { useForm } from "@formspree/react"
import validate from "../constants/validate-contact"
import Headings from "./headings"

export default function Newsletter({
  newsletter,
  title,
  description,
  bg = "uk-background-default",
}) {
  //console.log("Newsletter - newsletter: ", newsletter)

  const [state, handleSubmit] = useForm(process.env.GATSBY_NEWSLETTER_FORM)
  const [values, setValues] = useState({
    email: "",
  })
  const [errors, setErrors] = useState({
    email: "",
  })
  const [email, setEmail] = useState({
    email: "",
  })

  const handleChange = event => {
    const { name, value } = event.target
    setValues({
      ...values,
      [name]: value,
    })
    //console.log("values: ", values)
  }
  const manageSubmit = e => {
    e.preventDefault()

    // other logic or error checking logic here
    setErrors(validate(values))
    //console.log("errors: ", errors)
    //console.log("values: ", values)

    if (values.email.length > 0) {
      //console.log("values in if statement: ", values)
      setEmail(values.email)
      handleSubmit(e)
      resetForm()
      //console.log("Submitted successfully")
    }
  }

  const resetForm = () => {
    values.name = ""
    values.email = ""
    values.subject = ""
    values.message = ""
  }

  return (
    <div className={`uk-section ${bg} uk-padding-remove`}>
      <div className="uk-container  uk-margin-medium-top uk-margin-medium-bottom">
        <Headings title={title} description={description} bg={bg} />

        <div className="uk-width-3-4@m uk-margin-auto uk-padding-small">
          <div className="uk-text-center">
            <h2
              className="uk-h3 uk-margin-remove"
              style={{
                color: "#617F7F",
                fontWeight: "500",
                fontSize: "20px",
              }}
            >
              {newsletter.description}
            </h2>
          </div>
          <div className="uk-margin-medium-top">
            <div uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
              <form onSubmit={manageSubmit} noValidate>
                <div className="uk-grid-small" uk-grid="true">
                  <div className="uk-width-expand@s uk-first-column uk-margin-small-bottom">
                    <input
                      id="email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      placeholder="Enter your email..."
                      className="uk-input uk-form-small uk-width-1-1 uk-border-pill"
                      style={{
                        borderColor: "#617F7F",
                        color: "#617F7F",
                        borderRadius: "8px",
                        paddingTop: "19px",
                        paddingBottom: "19px",
                      }}
                    />
                    {errors.email && (
                      <div className="uk-alert-danger" uk-alert="true">
                        <p className="uk-form-danger">{errors.email}</p>
                      </div>
                    )}
                  </div>
                  <div className="uk-width-1-1 uk-width-auto@s">
                    <input
                      type="submit"
                      value="Subscribe"
                      className="uk-button uk-button-default uk-button-small"
                      style={{
                        borderColor: "#617F7F",
                        color: "#617F7F",
                        borderRadius: "8px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}

                      //style={{ backgroundColor: "#617F7F" }}
                    />
                  </div>
                </div>
                {state.succeeded && (
                  <div className="uk-alert-success" uk-alert="true">
                    <a href="/" className="uk-alert-close" uk-close="true">
                      {null}
                    </a>
                    <p>
                      Thank you {email}, your message has been sent
                      successfully, and you will added to our Newsletter mailing
                      list. You can{" "}
                      <a href="mailto:unsubscribe@savethestreetpooches.org?subject=Save The Street Pooches Sri Lanka - unsubscribe from Newsletter">
                        unsubscribe
                      </a>{" "}
                      at any time. and sending the email to remove.
                    </p>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

//export default newsletter
